<section class="error-box-outer">
  <div class="error-box">
    <div class="logo"><img src="../../../../assets/images/costa-coffee-logo-small.jpg" alt=""></div>
    <div class="bold m-t-20 m-b-15">Welcome to Costa GRID.</div>
    <ng-container *ngIf="loaded; else loadingTemplate">
      <ul>
        <li>Please select the sign in button to access Costa GRID.</li>
        <li>Please contact your administrator if you have any issues logging in.</li>
      </ul>
      <div class="button-row">
        <a id="btn-Login" tabindex="1"
           [routerLink]="['/login']"
           type="button" mat-flat-button
           color="primary" title="Sign In">Sign In</a>

      </div>
    </ng-container>
    <ng-template #loadingTemplate>
      <div class="spinner-box">
        <ngx-spinner bdColor="rgba(0,0,0,0.0)" size="large" color="#870027" type="ball-clip-rotate" [fullScreen]="false">
        </ngx-spinner>
      </div>
      <div class="title">
        <div>Loading...</div>
      </div>
    </ng-template>
  </div>
  <app-footer></app-footer>
</section>
