<header (window:beforeunload)="doBeforeUnload()"
        class="main-header"
        [class.has-marqee]="tickerTapMessageService.scheduleMessages.length">
  <a id="logo"
     [routerLink]="(this.roleService.objRole.isUserAuditorGRID || this.roleService.objRole.isUserAuditorLevel1 || this.roleService.objRole.isUserAuditorLevel2 || this.roleService.objRole.isUserAuditorLevel3) ? ['/user-audit'] : ['/dashboard']"
     class="logo order-1">
    <img class="img-fluid" src="../../../../assets/images/logo.svg" alt="Costa Coffee">
  </a>

  <ng-marquee class="marqee order-3 order-md-2" [stopOnHover]="true" speed="drowsy"
              *ngIf="tickerTapMessageService.scheduleMessages.length">
    <mark class="message-class" [innerHTML]="msg['message']"
          *ngFor="let msg of tickerTapMessageService.scheduleMessages">
    </mark>
  </ng-marquee>

  <nav class="navbar navbar-static-top order-2 order-md-3">
    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">
        <li id="users" class="dropdown user user-menu ">
          <a href="#" class="dropdown-toggle align-items-center" data-toggle="dropdown">
                        <span class="user-img">
                <img class="user-image" src="../../assets/images/welcome-user.svg" height="30" width="30" />
              </span>
          </a>
          <ul class="dropdown-menu user-drop-box">
            <li class="welcome">
              <span>Welcome <b>{{ roleService.userName }}</b></span>
            </li>
            <li class="user-body">
              <a id="edit-account" href="javascript:void(0)" (click)="Logout()"><i class="m-r-5"><img
                src="../../../../assets/images/logout-icon.svg" alt="Logout"></i> Sign out</a>
            </li>

          </ul>
        </li>
      </ul>
    </div>
  </nav>

</header>
<div class="notification-alert" *ngIf="alerts.length">
  <div *ngFor="let msg of alerts">
    <img src="../../../../assets/images/notifiacation-alert-icon.svg" alt="">
    {{ msg.message }}
  </div>
</div>
<ng-template #confirmDialogTemplate>
  <app-confirm-dialog title="CONFIRMATION" successTitle="Yes" cancelTitle="No"
                      message="{{dialogTexts.message}}"
                      (onConfirm)="onConfirm()" (onCancel)="onCancel()">
  </app-confirm-dialog>
</ng-template>
